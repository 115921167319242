<script>
import {UProgress} from "uloc-vue"

export default {
  inject: ['attachContainer'],
  props: ['task'],
  name: 'task-attach',
  data() {
    return {}
  },
  computed: {
    container () {
      return this.attachContainer
    }
  },
  mounted() {
  },
  destroyed() {
  },
  methods: {},
  components: {
    UProgress
  }
}
</script>

<template>
  <div class="task-attach non-selectable">
    <div v-if="!container.isUploading || !container.files || !container.files.length" class="flex items-center">
      <u-btn @click="container.$refs.input.click()" color="primary" icon="cloud-upload" size="sm" icon-type="fa" icon-style="light" noCaps
             label="Anexar arquivos"></u-btn>
      <span class="m-l">ou solte os arquivos aqui <u-icon name="upload" type="fa" icon-style="light"
                                                          class="m-l-xs"/></span>
    </div>
    <div v-else class="pessoa-arquivo-area cursor-pointer dropzone-area flex flex-center justify-between">
      <div class="col-grow w-full wrapper">
        <div class="uploader-container">
          <ul>
            <li v-for="(file, i) in container.files" :key="i">
              <div class="filename">{{ file.file.name }}</div>
              <div class="filesize">
                <u-progress v-if="file.copying" :percentage="file.progress" :animate="true"
                            :color="file.progress < 85 ? 'grey' : 'positive'" height="10px"/>
                <span v-else-if="file.fail"><a @click.prevent.stop="container.upload(file)"
                                               style="text-transform: none; color: red">Falha. Tentar novamente</a></span>
                <span v-else>{{ container.filesize(file.file.size) }}</span>
              </div>
              <div class="options">
                <u-icon @click.native.prevent.stop="container.removeFile(file)" name="delete"/>
              </div>
            </li>
          </ul>
        </div>
        <div class="flex justify-between m-t uploader-options">
          <div>
            <a @click.stop.prevent="container.$refs.input.click()" class="pos-rlt" style="z-index: 3">
              <u-icon name="attach_file"/>
              Anexar mais arquivos</a>
          </div>
          <div class="text-right"><a @click.stop.prevent="container.reset">Cancelar envios</a></div>
        </div>
      </div>
    </div>
  </div>
</template>

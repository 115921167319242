import {fileToBase64} from "@/utils/fileToBase64"
import {humanStorageSize} from "uloc-vue/src/utils/format"
import {uploadArquivoTask} from "@/domain/tarefas/services"

export default {
    data () {
        return {
            dropzone: false,
            files: [],
            fileTypes: '*',
            isUploading: false,
            uploadService: uploadArquivoTask
        }
    },
    computed: {
        existsFilesToUpload () {
            return this.files.some(file => !file.done)
        }
    },
    methods: {
        dropzoneEnter (e) {
            this.dropzone = true
        },
        dropClickEvent (e) {
            !this.files.length && this.$refs.input.click()
        },
        inputListener (e) {
            console.log(e)
            this.dropzone = false
            // let files = []

            let bindFile = (file) => {
                if (!file.progress) {
                    return {
                        file: file,
                        filename: file.name,
                        done: false,
                        copying: false,
                        progress: 0,
                        fail: false,
                        success: false,
                        tipo: this.tipoDefault,
                        nome: null,
                        permissao: 0
                    }
                }
                return file
            }

            // For drag via dataTransfer
            if (e.dataTransfer && e.dataTransfer.items) {
                // Use DataTransferItemList interface to access the file(s)
                for (let i = 0; i < e.dataTransfer.items.length; i++) {
                    // If dropped items aren't files, reject them
                    if (e.dataTransfer.items[i].kind === 'file') {
                        this.files.push(bindFile(e.dataTransfer.items[i].getAsFile()))
                    }
                }
            } else if (e.dataTransfer && e.dataTransfer.files) {
                // Use DataTransfer interface to access the file(s)
                for (let i = 0; i < e.dataTransfer.files.length; i++) {
                    this.files.push(bindFile(e.dataTransfer.files))
                }
            }

            // For input via target.files
            if (e.target.files && e.target.files.length > 0) {
                for (let i = 0; i < e.target.files.length; i++) {
                    this.files.push(bindFile(e.target.files[i]))
                }
            }

            this.isUploading = true
            console.log('Adicionando arquivos: ', this.files)
            this.task.id && this.iniciaEnvios()
        },
        removeFile (file) {
            // @TODO: <RAPHAEL> Se já foi feito upload (verificar file.id), chamar api para remover o arquivo (criar endpoint)
            console.log('Removendo arquivo')
            this.files.splice(this.files.indexOf(file), 1)
        },
        upload (file) {
            console.log('Fazendo upload ', file)
            file.done = true
            file.copying = true
            file.progress = 0
            this.$progress = setInterval(() => {
                let p = file.progress + Math.floor(Math.random() * 8 + 10)
                file.progress = p < 100 ? p : 100
                if (file.progress >= 100) {
                    clearInterval(this.$progress)
                }
            }, 20)

            /*return new Promise((resolve, reject) => {
              setTimeout(() => {
                file.copying = false
                file.progress = 100
                file.success = true
                resolve('done!')
              }, 3000)
            });*/
            return new Promise((resolve, reject) => {
                fileToBase64(file.file)
                    .then(_file => {
                        this.uploadService(this.task.id,{
                            data: _file,
                            ...file
                        })
                            .then(response => {
                                this.successCallback && this.successCallback(response)
                                file.id = response.data.id
                                file.copying = false
                                file.progress = 100
                                file.success = true
                                this.task.attachments.push(response.data)
                                resolve(true)
                            })
                            .catch(error => {
                                this.failureCallback && this.failureCallback(error)
                                file.done = false
                                file.copying = false
                                file.progress = 0
                                file.success = false
                                file.fail = true
                                this.alertApiError(error)
                                resolve(false)
                            })
                    })
            })
        },
        async iniciaEnvios () {
            return new Promise(async (resolve) => {
                console.log('Iniciando envio...')
                if (!this.files.length) {
                    return
                }
                for (let i = 0; i < this.files.length; i++) {
                    if (!this.files[i].done) {
                        await this.upload(this.files[i])
                    }
                }
                resolve()
            })
        },
        filesize (size) {
            return humanStorageSize(size)
        },
        successCallback (response) {
            console.log('Novo arquivo event', response.data)
            this.$emit('novoArquivo', response.data)
        },
        reset () {
            this.files = []
            this.isUploading = false
        }
    }
}

<script>
import TaskAttach from "./attach"
import TaskAttachmentsFiles from "./files"
import uploadareaMixin from "components/suporte/components/task/include/attachment/uploadareaMixin"

export default {
  name: 'TaskAttachments',
  mixins: [uploadareaMixin],
  provide: function () {
    return {
      attachContainer: this
    }
  },
  props: {
    task: {
      required: true
    }
  },
  data () {
    return {
      files: []
    }
  },
  computed: {
    classes () {
      let css = []
      return css
    },
  },
  mounted () {
  },
  destroyed () {
  },
  methods: {},
  components: {TaskAttachmentsFiles, TaskAttach}
}
</script>

<template>
  <div class="task-attach-dropzone no-wrap h-full flex column content-between"
       :class="{'dropzoneEnter': dropzone, 'done': false}"
       @dragenter.prevent.stop="dropzoneEnter"
       @dragover.prevent.stop="dropzone = true"
       @drop.prevent.stop="inputListener"
       @dragleave.prevent.stop="dropzone = false">
    <input @change="inputListener($event)" :accept="fileTypes" type="file" id="input" ref="input" multiple
           v-show="false">
    <div class="task-attachments col-grow-1" :class="classes">
      <task-attach :task="task"/>
      <task-attachments-files :task="task"/>
    </div>
  </div>
</template>
